import React from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Icon, { IconName } from '../Icon';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Input component, default is 'input'.
   */
  as?: string | React.ComponentType<any>;
  /**
   * The content of the label.
   */
  label?: React.ReactNode;
  /**
   * Error state. If used as string error text will appear below the input.
   */
  error?: boolean | React.ReactNode;
  /**
   * Success state. Makes icon green and adds default 'check-circle' icon.
   */
  success?: boolean;
  /**
   * Name of an icon to dispay on the right side of the input.
   */
  icon?: IconName | React.ReactNode;
  /**
   * Removes focus from input when "Enter" is pressed.
   */
  blurOnSubmit?: boolean;
  /*
   ** Takes 100% width of the container
   */
  fluid?: boolean;
  /*
   ** Clears input value
   */
  clear?: (e: any) => void;
  /*
   ** Used with input mask library
   */
  mask?: string;
  /*
   ** Attribute for auto tests
   */
  'data-cy'?: string;
  value?: any;
  iconPosition?: 'left' | 'right';
}

const Input = React.forwardRef<any, InputProps>(
  (
    {
      id,
      as: Component = 'input',
      label,
      error,
      success,
      icon,
      fluid,
      blurOnSubmit,
      className,
      onKeyPress,
      value,
      // clear,
      iconPosition,
      'data-cy': dataCY,
      ...props
    },
    ref
  ) => {
    const inputRef = ref as React.MutableRefObject<any>;
    // eslint-disable-next-line no-param-reassign
    if (success && !icon) icon = 'check-circle';

    const handleKeyPress = e => {
      if (blurOnSubmit && e.charCode === 13) e.target.blur();
      if (onKeyPress) onKeyPress(e);
    };

    // const showClear = clear && value;

    return (
      <StyledInput
        className={clsx('input', { label, icon, fluid, error, success }, className)}
        iconPosition={iconPosition}
        data-cy={dataCY}>
        <label htmlFor={id}>{label}</label>
        <div className="input-wrapper">
          <Component
            id={id}
            ref={inputRef}
            value={value}
            onKeyPress={handleKeyPress}
            className="input-component"
            data-cy={dataCY ? `${dataCY}_field` : undefined}
            {...props}
          />
          {typeof icon === 'string' ? <Icon name={icon as IconName} /> : icon}
        </div>
        <div className="error-text">{error}</div>
      </StyledInput>
    );
  }
);

export default React.memo(Input);

export const StyledInput = styled.div<{ iconPosition?: 'left' | 'right' }>`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  fill: ${props => props.theme.colors.grayDark};

  .input-wrapper {
    position: relative;

    .input-component {
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid ${props => props.theme.colors.borderColor};
      border-radius: ${props => props.theme.misc.borderRadius};
      padding: 0 8px;
      font-size: 12px;
      line-height: 16px;
      outline: none;

      &:hover,
      &:focus {
        border-color: black;
      }

      &:disabled {
        background: ${props => props.theme.colors.lightGray};
        color: ${props => props.theme.colors.gray};
        pointer-events: none;

        &::placeholder {
          color: ${props => props.theme.colors.gray};
        }
      }

      &::placeholder {
        color: ${props => props.theme.colors.grayDark};
      }
    }

    textarea.input-component {
      padding: 12px 8px;
      height: 160px;
    }

    & > .icon {
      position: absolute;
      ${props => (props.iconPosition === 'left' ? `left: 8px` : `right: 8px`)};
      top: 50%;
      transform: translateY(-50%);
    }

    .icon-mini-x {
      cursor: pointer;
    }

    .clear {
      right: 8px;
      left: initial;
      fill: ${props => props.theme.colors.red};
      width: 15px;
      height: 15px;
    }
  }

  label {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 4px;
    color: #000;

    &:empty {
      display: none;
    }
  }

  .error-text {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.colors.red};
    margin-top: 4px;
    &:empty {
      display: none;
    }
  }

  &.icon .input-wrapper {
    .input-component {
      ${props => (props.iconPosition === 'left' ? `padding-left: 26px` : `padding-right: 26px`)};
    }
  }

  &.fluid {
    width: 100%;
  }

  &.error .input-wrapper {
    .input-component {
      border-color: ${props => props.theme.colors.red};

      &:hover {
        border-color: ${props => props.theme.colors.errorRed};
      }
    }

    & > .icon {
      fill: ${props => props.theme.colors.red};
    }
  }

  &.success .input-wrapper {
    & > .icon {
      fill: ${props => props.theme.colors.green};
    }
  }
`;
