import { ReactComponent as ArrowSimpleSVG } from './arrow-simple.svg';
import { ReactComponent as ArrowSVG } from './arrow.svg';
import { ReactComponent as BurgerMenuSVG } from './burger-menu.svg';
import { ReactComponent as CalendarSVG } from './calendar.svg';
import { ReactComponent as CameraSVG } from './camera.svg';
import { ReactComponent as CancelMiniSVG } from './cancel-mini.svg';
import { ReactComponent as CheckmarkSVG } from './checkmark.svg';
import { ReactComponent as CheckSVG } from './check.svg';
import { ReactComponent as ChevronSVG } from './chevron.svg';
import { ReactComponent as CloseSVG } from './close.svg';
import { ReactComponent as CloudUploadSVG } from './cloud-upload.svg';
import { ReactComponent as deleteSVG } from './delete.svg';
import { ReactComponent as DeleteUserSVG } from './delete-user.svg';
import { ReactComponent as DownloadSVG } from './download.svg';
import { ReactComponent as DocumentFillSVG } from './document-fill.svg';
import { ReactComponent as DocumentFolderSVG } from './document-folder.svg';
import { ReactComponent as DocumentFmageSVG } from './document-image.svg';
import { ReactComponent as DocumentFileSVG } from './document-file.svg';
import { ReactComponent as EyeSVG } from './eye.svg';
import { ReactComponent as EyeCrossedSVG } from './eye-crossed.svg';
import { ReactComponent as EmailSVG } from './email.svg';
import { ReactComponent as FCBoldSVG } from './fc-bold.svg';
import { ReactComponent as FCBulletedListSVG } from './fc-bulleted-list.svg';
import { ReactComponent as FCCopySVG } from './fc-copy.svg';
import { ReactComponent as FCDocumentSVG } from './fc-document.svg';
import { ReactComponent as FCFileSVG } from './fc-file.svg';
import { ReactComponent as FCIndentSVG } from './fc-indent.svg';
import { ReactComponent as FCItalicSVG } from './fc-italic.svg';
import { ReactComponent as FCJustifyCenterSVG } from './fc-justify-center.svg';
import { ReactComponent as FCJustifyFullSVG } from './fc-justify-full.svg';
import { ReactComponent as FCJustifyLeftSVG } from './fc-justify-left.svg';
import { ReactComponent as FCJustifyRightSVG } from './fc-justify-right.svg';
import { ReactComponent as FCLinkSVG } from './fc-link.svg';
import { ReactComponent as FCNewForm } from './fc-new-form.svg';
import { ReactComponent as FCNumberedListSVG } from './fc-numbered-list.svg';
import { ReactComponent as FCOutdentSVG } from './fc-outdent.svg';
import { ReactComponent as FCSaveSVG } from './fc-save.svg';
import { ReactComponent as FCUnderlineSVG } from './fc-underline.svg';
import { ReactComponent as FilterSVG } from './filter.svg';
import { ReactComponent as FolderOutlneSVG } from './folder-outline.svg';
import { ReactComponent as FolderSVG } from './folder.svg';
import { ReactComponent as FolderPlusSVG } from './folder-plus.svg';
import { ReactComponent as GearSVG } from './gear.svg';
import { ReactComponent as ImageSVG } from './image.svg';
import { ReactComponent as InfoSVG } from './info.svg';
import { ReactComponent as LocationSVG } from './location.svg';
import { ReactComponent as MasterCardSVG } from './master-card.svg';
import { ReactComponent as MenuDotsSVG } from './menu-dots.svg';
import { ReactComponent as MenuHamburgerSVG } from './menu-hamburger.svg';
import { ReactComponent as MiniXSVG } from './mini-x.svg';
import { ReactComponent as PenSVG } from './pen.svg';
import { ReactComponent as PhoneSVG } from './phone.svg';
import { ReactComponent as PlaceholderUserSVG } from './placeholder-user.svg';
import { ReactComponent as PlusMiniSVG } from './plus-mini.svg';
import { ReactComponent as PlusSVG } from './plus.svg';
import { ReactComponent as SearchSVG } from './search.svg';
import { ReactComponent as SmartphoneSVG } from './smartphone.svg';
import { ReactComponent as StarSVG } from './star.svg';
import { ReactComponent as TimeSVG } from './time.svg';
import { ReactComponent as TrashSVG } from './trash.svg';
import { ReactComponent as UserSVG } from './user.svg';
import { ReactComponent as VisaCardSVG } from './visa-card.svg';
import { ReactComponent as UploadSVG } from './upload.svg';
// import { ReactComponent as ExclamationTGSVG } from './exclamation-tg.svg';

const icons = {
  'arrow-simple': ArrowSimpleSVG,
  'burger-menu': BurgerMenuSVG,
  'cancel-mini': CancelMiniSVG,
  'document-fill': DocumentFillSVG,
  'eye-crossed': EyeCrossedSVG,
  'fc-bold': FCBoldSVG,
  'fc-bulleted-list': FCBulletedListSVG,
  'fc-copy': FCCopySVG,
  'fc-document': FCDocumentSVG,
  'fc-file': FCFileSVG,
  'fc-indent': FCIndentSVG,
  'fc-italic': FCItalicSVG,
  'fc-justify-center': FCJustifyCenterSVG,
  'fc-justify-full': FCJustifyFullSVG,
  'fc-justify-left': FCJustifyLeftSVG,
  'fc-justify-right': FCJustifyRightSVG,
  'fc-link': FCLinkSVG,
  'fc-new-form': FCNewForm,
  'fc-numbered-list': FCNumberedListSVG,
  'fc-outdent': FCOutdentSVG,
  'fc-save': FCSaveSVG,
  'fc-underline': FCUnderlineSVG,
  'folder-outline': FolderOutlneSVG,
  'master-card': MasterCardSVG,
  'menu-dots': MenuDotsSVG,
  'menu-hamburger': MenuHamburgerSVG,
  'mini-x': MiniXSVG,
  'placeholder-user': PlaceholderUserSVG,
  'plus-mini': PlusMiniSVG,
  'delete-user': DeleteUserSVG,
  'folder-plus': FolderPlusSVG,
  'document-folder': DocumentFolderSVG,
  'document-image': DocumentFmageSVG,
  'document-file': DocumentFileSVG,
  'cloud-upload': CloudUploadSVG,
  'visa-card': VisaCardSVG,
  arrow: ArrowSVG,
  calendar: CalendarSVG,
  camera: CameraSVG,
  check: CheckSVG,
  checkmark: CheckmarkSVG,
  chevron: ChevronSVG,
  close: CloseSVG,
  delete: deleteSVG,
  download: DownloadSVG,
  eye: EyeSVG,
  image: ImageSVG,
  gear: GearSVG,
  email: EmailSVG,
  filter: FilterSVG,
  folder: FolderSVG,
  info: InfoSVG,
  location: LocationSVG,
  pen: PenSVG,
  phone: PhoneSVG,
  plus: PlusSVG,
  search: SearchSVG,
  smartphone: SmartphoneSVG,
  star: StarSVG,
  time: TimeSVG,
  trash: TrashSVG,
  user: UserSVG,
  download: DownloadSVG,
  upload: UploadSVG
};

export default icons;
